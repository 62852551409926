<template>
  <v-container>
    <h1 class="mb-4">{{ $vuetify.lang.t('$vuetify.faq.ueberschrift') }}</h1>
    <FAQ/>
  </v-container>
</template>

<script>
import FAQ from '@/components/FAQ';

export default {
  components: {FAQ}
}
</script>
